import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import {
  renderActivityAssignee,
  renderActivityPriority,
  renderActivityStatus,
  renderActivityTags,
} from "@/apps/tatar/activityApp/views/list/APList";
import StructLoader from "@/components/StructLoader/StructLoader";
import WrappedText from "@/components/WrappedText/WrappedText";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { openCommentsModal } from "@/modules/comments-module/CommentsModal";
import InfiniteTable from "@/redux/actions/application/application-infinite-table-actions";
import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import { UnitStructType } from "@/redux/reducers/struct/StructInterface";
import { MatchQuery } from "@/services/DataService";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Truncate from "react-truncate";
import "./LinkedActivitiesList.scss";

interface LinkedActivitiesListProps {
  assetId: string;
  type: string;
  structType: UnitStructType;
  assetType: string;
  title: string;
  activityStatus: "active" | "inactive" | "waiting";
  search: string;
  overwriteMatchQuery?: MatchQuery;
  renderData?: (
    activity: APActivity,
    struct: ActivityAbstractStructClass<any>
  ) => React.ReactElement;
}
const LinkedActivitiesList = (props: LinkedActivitiesListProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      InfiniteTable.setSearch(
        `linked-activities-list-${props.assetType}`,
        props.search
      )
    );
  }, [props.search]);
  return (
    <StructLoader
      block
      unitType={props.type}
      structTypes={[props.structType, "unit", "orga", "category"]}
      render={(structs) => {
        const activityStruct = structs[0] as ActivityAbstractStructClass<any>;

        const statusFilter = activityStruct.getStatusByActivityStatus(
          props.activityStatus
        );
        return (
          <div className={classNames(`linked-activities-list`)}>
            <div className={`activity-title`}>{props.title}</div>
            <ListComponent
              identifier={`linked-activities-list-${props.assetType}`}
              assetType={props.assetType}
              additionalMatchQuery={MQ.and(
                props.overwriteMatchQuery
                  ? props.overwriteMatchQuery
                  : MQ.or(
                      MQ.eq("data.relations.assetId", props.assetId),
                      MQ.eq("data.linkedAsset.assetId", props.assetId)
                    ),
                MQ.in(
                  "data.status",
                  statusFilter.map((e) => e.id)
                )
              )}
              render={(activity: APActivity) => (
                <CBRentalAgreementActivityEntry
                  {...props}
                  struct={activityStruct}
                  activity={activity}
                />
              )}
            />
          </div>
        );
      }}
    />
  );
};

export default LinkedActivitiesList;

const CBRentalAgreementActivityEntry = (
  props: LinkedActivitiesListProps & {
    activity: APActivity;
    struct: ActivityAbstractStructClass<any>;
  }
) => {
  const [showTruncated, setShowTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const toggleLines = () => setExpanded(!expanded);

  return (
    <div className={`activity-entry`}>
      <div className={`left`}>
        <DebugDataComponent data={props.activity} />
        <div className={`id`}>{props.activity.data.activityId}</div>
        <div className={`title`}>{props.activity.data.displayName}</div>

        {props.activity.data.shortDescription && (
          <div className={`description`}>
            <Truncate
              onTruncate={
                (truncated) => setShowTruncated(truncated || showTruncated) //only show when really truncated stuff
              }
              lines={!expanded && 2}
            >
              {/* {props.activity.data.shortDescription} */}
              <WrappedText text={props.activity.data.shortDescription} />
            </Truncate>
            {showTruncated && (
              <div className={`toggle-lines`}>
                <a onClick={() => toggleLines()}>
                  {expanded
                    ? i18n.t("Global.Labels.less", "Weniger")
                    : i18n.t("Global.Labels.more", "Mehr")}
                </a>
              </div>
            )}
          </div>
        )}
        {props.renderData && (
          <div className={`additional-data`}>
            {props.renderData(props.activity, props.struct)}
          </div>
        )}
        <div className={`sub-data`}>
          {renderActivityStatus(props.activity, props.struct)}
          {renderActivityPriority(props.activity)}
          {renderActivityAssignee(props.activity)}
          {renderActivityTags(props.activity, props.struct)}
        </div>
      </div>
      <div className={`right`}>
        <BFButton
          noPadding
          appearance="link"
          onClick={() => {
            openCommentsModal({
              assetId: props.activity._id,
              assetType: props.assetType,
              type: props.type,
              identifier: "linked-activities-list-comments-modal",
              allowMailUpload: false,
              mode: "list",
            });
          }}
        >
          <BfIcon {...DefaultIcons.CHAT} size="sm" />
        </BFButton>
        <div>
          <BFDetailsButton
            noPadding
            appearance="link"
            data={{
              assetId: props.activity._id,
              assetType: props.assetType,
              type: props.type,
            }}
          >
            <BfIcon {...DefaultIcons.OPEN_LINK} size="sm" />
          </BFDetailsButton>
        </div>
      </div>
    </div>
  );
};
