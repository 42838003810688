import { useVirtualizer, Virtualizer } from "@tanstack/react-virtual";
import React, { UIEventHandler, useEffect } from "react";
import "./BFVirtualized.scss";

interface Props {
  data: any[];
  height?: string | number;
  renderElement: (
    data: any,
    index: number,
    allData: any[],
    params?: any
  ) => React.ReactNode;
  getStyles?: (data: any, index: number) => React.CSSProperties;
  estimatedSize?: number;
  calculateSize?: (index: number) => number | undefined;
  overscan?: number;
  onScroll?: UIEventHandler<HTMLElement>;
  params?: any;
  scrollingDelay?: number;
  paramsConverter?: (data: any, index: number, params: any) => any;
  className?: string;
  width?: string | number;
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  emptyText?: string;
  virtualizedRef?: React.MutableRefObject<Virtualizer<HTMLDivElement, Element>>;
}

const BFVirtualized = (props: Props) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: props.data.length,
    enableSmoothScroll: false,

    getScrollElement: () => parentRef.current,
    // try to calulate the size, otherwise use estimateSize and then defaults to 35 if not set
    estimateSize: (index: number) =>
      (props.calculateSize && props.calculateSize(index)) ||
      props.estimatedSize ||
      35,
    overscan: props.overscan || 10,
    scrollingDelay: props.scrollingDelay || 0,
    // scrollMargin: 10,
  });

  useEffect(() => {
    // rowVirtualizer.setOptions({
    //   ...rowVirtualizer.options,
    //   estimateSize: (index: number) =>
    //     (props.calculateSize && props.calculateSize(index)) ||
    //     props.estimatedSize ||
    //     35,
    // });
    rowVirtualizer.measure();
  }, [props.calculateSize]);
  if (props.virtualizedRef) {
    props.virtualizedRef.current = rowVirtualizer;
  }

  // rowVirtualizer.measure();

  return (
    <div
      ref={(ref) => {
        parentRef.current = ref;
        if (props.scrollRef) {
          props.scrollRef.current = ref;
        }
      }}
      className={`bf-virtualized ${props.className || ""}`}
      style={{
        width: props.width,
        height: props.height,
        overflow: "auto",
      }}
      onScroll={props.onScroll}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {props.data && props.data.length === 0 && props.emptyText && (
          <div className="empty-text">{props.emptyText}</div>
        )}
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const styles = props.getStyles
            ? props.getStyles(props.data[virtualRow.index], virtualRow.index)
            : {};
          return (
            <div
              key={virtualRow.index}
              className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
              style={{
                ...styles,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {props.renderElement(
                props.data[virtualRow.index],
                virtualRow.index,
                props.data,
                props.paramsConverter
                  ? props.paramsConverter(
                      props.data[virtualRow.index],
                      virtualRow.index,
                      props.params
                    )
                  : props.params
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BFVirtualized;
