import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import {
  AccountingAccount,
  AccountingBooking,
  AccountingBookingGroup,
  AccountingBookingType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import StructLoader from "@/components/StructLoader/StructLoader";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { useHttpCache } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import NumberUtils from "@/utils/ NumberUtils";
import ArrayUtils from "@/utils/ArrayUtils";
import { usePrevious } from "@/utils/Hooks";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import "./CBRentalAgreementDebitPositions.scss";
import CBRentalAgreementPositionInfo from "./CBRentalAgreementPositionInfo";

export type DebitPositionResult = AccountingBookingGroup & {
  bookings: AccountingBooking[];
  contraBookings: AccountingBooking[];
  displayBookings: AccountingBooking[];
};
export interface ManualBookingPosition {
  name: string;
  id: string;
  taxRate: number;
}

export type DebitPositionValue = {
  value: {
    amount: number;
    currency: string;
  };
  bookingType: "S" | "H";
  costId: string;
  note: string;
};
interface CBRentalAgreementDebitPositionsProps {
  accountingData: AccountingData;
  account: string;
}

const calcAmount = (booking: AccountingBooking, pos: DebitPositionResult) => {
  const relevantContraBookings = pos.contraBookings
    // .map((e) => AccountingService.getCorrectedBooking(pos.data.account, e))
    .filter((e) => e.data.costId === booking._id);

  const amount = NumberUtils.normalizeDecimal(
    booking.data.value.converted.amount *
      (booking.data.bookingType === AccountingBookingType.HABEN ? -1 : 1) -
      _.sum(
        relevantContraBookings
          .filter((e) => e.data.bookingType === AccountingBookingType.HABEN)
          .map((e) => e.data.value.converted.amount)
      ) +
      _.sum(
        relevantContraBookings
          .filter((e) => e.data.bookingType === AccountingBookingType.SOLL)
          .map((e) => e.data.value.converted.amount)
      )
  );

  return amount;
};

type DebitPositionResultWithSaldo = DebitPositionResult & {
  saldo: number;
};
const CBRentalAgreementDebitPositions = (
  props: CBRentalAgreementDebitPositionsProps
) => {
  const [dataTransformed, setDataTransformed] = useState<
    DebitPositionResultWithSaldo[]
  >([]);
  const [openState, setOpenState] = useState<string[]>([]);
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-all-debit-positions-${props.account}`,
    `/api/accounting/${props.accountingData.accounting.data.entity}/${props.account}/getDebitPositions`,
    "get",
    null,
    undefined,
    undefined
  );
  const prev = usePrevious(data?.data);
  useEffect(() => {
    if (!_.eq(prev, data?.data)) {
      const dataTransformed = ArrayUtils.sortData(data.data, [
        {
          dir: "asc",
          key: "data.date",
        },
        {
          dir: "asc",
          key: "_id",
        },
      ])
        .reduce((prev, current, index) => {
          // ...pos,
          // saldo:

          return [
            ...prev,
            {
              ...current,
              saldo:
                (prev[index - 1]?.saldo || 0) -
                current.data.sum.soll +
                current.data.sum.haben,
            },
          ];
        }, [])
        .reverse();

      setDataTransformed(dataTransformed);
    }
  }, [data]);

  const headers: {
    label: string;
    positionColSpan?: number;
    fixedWith?: number;
    renderPosition: (pos: DebitPositionResult) => JSX.Element;
    renderBooking: (
      booking: AccountingBooking,
      pos: DebitPositionResult,
      subIndex: number,
      subLength: number
    ) => JSX.Element;
    className?: string;
  }[] = [
    {
      label: "",
      fixedWith: 28,
      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return (
          <div className="collapse-button">
            <BFButton
              onClick={() => {
                if (openState.includes(pos._id)) {
                  setOpenState(openState.filter((id) => id !== pos._id));
                } else {
                  setOpenState([...openState, pos._id]);
                }
              }}
            >
              <BfIcon
                size="xxs"
                {...DefaultIcons.CHEVRON}
                className={`${openState.includes(pos._id) ? "open" : ""}`}
              />
            </BFButton>
          </div>
        );
      },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t("cb:RentalAgreement.DebitPositions.Date", "Datum"),
      fixedWith: 92,

      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return <div>{StringUtils.formatDate(pos.data.date)} </div>;
      },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t(
        "cb:RentalAgreement.OpenDebitPositions.BookingText",
        "Buchungstext"
      ),

      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return <div>{pos.data.displayName} </div>;
      },
      positionColSpan: 3,
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo,
        subIndex: number,
        subLength: number
      ) => {
        return (
          <AssetLoader
            assetType={AssetTypes.Accounting.Account}
            id={booking.data.contraAccount}
            render={(account: AccountingAccount) => {
              return (
                <div className={`position-name`}>
                  <div
                    className={`position-parent-indicator ${
                      subIndex === subLength - 1 ? "last" : ""
                    }`}
                  >
                    <div className={`position-border`} />
                    <div className={`position-indicator`} />
                  </div>
                  <div className={`position-text`}>
                    <div className={`name`}>
                      {LanguageService.translateLabel(account.data.displayName)}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        );
      },
    },

    {
      label: "",
      renderPosition: null,
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        let secondAccount = null;

        if (booking.data.costId) {
          const displayBookingFound = pos.displayBookings.find(
            (e) => e._id === booking.data.costId
          );

          return (
            <AssetLoader
              assetType={AssetTypes.Accounting.Account}
              id={displayBookingFound.data.contraAccount}
              render={(account: AccountingAccount) => {
                return (
                  <div>
                    {secondAccount && "-> "}
                    {LanguageService.translateLabel(secondAccount?.displayName)}
                  </div>
                );
              }}
            />
          );
        }
        return null;
      },
    },
    {
      label: "",
      renderPosition: null,
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        let addLabel = "";

        if (booking.data.costId) {
          const displayBookingFound = pos.displayBookings.find(
            (e) => e._id === booking.data.costId
          );

          let el = data.data.find((e) =>
            e.bookings.find((a) => a._id === displayBookingFound._id)
          );

          if (el) {
            addLabel = LanguageService.translateLabel(el.data.displayName);
          }
        }
        return <div>{addLabel}</div>;
      },
    },

    {
      label: "",
      fixedWith: 85,
      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return <CBRentalAgreementPositionInfo pos={pos} />;
      },
      renderBooking: () => {
        return null;
      },
    },
    {
      label: i18n.t("cb:RentalAgreement.DebitPositions.Soll", "Soll"),
      className: "currency-column",
      fixedWith: 100,

      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return (
          <div>
            {StringUtils.formatCurrency(
              pos.data.sum.soll,
              true,
              undefined,
              props.accountingData.accounting.data.currency
            )}
          </div>
        );
      },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        return (
          <div>
            {booking.data.bookingType === AccountingBookingType.SOLL
              ? StringUtils.formatCurrency(
                  booking.data.value?.amount,
                  true,
                  undefined,
                  booking.data.value?.currency
                )
              : ""}
          </div>
        );
      },
    },
    {
      label: i18n.t("cb:RentalAgreement.DebitPositions.Haben", "Haben"),
      className: "currency-column",

      fixedWith: 100,
      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return (
          <div>
            {StringUtils.formatCurrency(
              pos.data.sum.haben,
              true,
              undefined,
              props.accountingData.accounting.data.currency
            )}
          </div>
        );
      },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        return (
          <div>
            {booking.data.bookingType === AccountingBookingType.HABEN
              ? StringUtils.formatCurrency(
                  booking.data.value?.amount,
                  true,
                  undefined,
                  booking.data.value?.currency
                )
              : ""}
          </div>
        );
      },
    },
    {
      label: i18n.t("cb:RentalAgreement.DebitPositions.LfdSaldo", "lfd. Saldo"),
      className: "currency-column",

      fixedWith: 100,
      renderPosition: (pos: DebitPositionResultWithSaldo) => {
        return (
          <div>
            {StringUtils.formatCurrency(
              pos.saldo,
              true,
              undefined,
              props.accountingData.accounting.data.currency
            )}
          </div>
        );
      },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResultWithSaldo
      ) => {
        return <div></div>;
      },
    },
  ].filter((e) => e);

  return (
    <StructLoader
      unitType={props.accountingData.accounting.data.type}
      structTypes={["objectKind", "orga"]}
      render={() => {
        return (
          <div className={classNames(`cb-rental-agreement-debit-positions`)}>
            <DebugDataComponent
              data={{
                date: data,
              }}
            />
            <table>
              <tr className={`head-row`}>
                {headers.map((e, index) => (
                  <th
                    className={classNames(e.className)}
                    style={{
                      width: e.fixedWith,
                    }}
                    key={index}
                  >
                    {e.label}
                  </th>
                ))}
              </tr>
              {data.state === "loading" && (
                <tr>
                  <td colSpan={headers.length}>
                    <Loader />
                  </td>
                </tr>
              )}
              {data.state === "error" && (
                <tr>
                  <td colSpan={headers.length}>
                    {i18n.t(
                      "cb:RentalAgreement.DebitPositions.Error",
                      "Fehler beim Laden der Positionen"
                    )}
                  </td>
                </tr>
              )}
              {data.state === "success" && (
                <>
                  <DebugDataComponent data={data.data} />
                  {data.data.length === 0 && (
                    <tr>
                      <td
                        colSpan={headers.length}
                        className={`no-debit-position`}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.DebitPositions.NoOpenDebitPositions",
                          "Keine Buchungen vorhanden"
                        )}
                      </td>
                    </tr>
                  )}
                  {dataTransformed.map((pos) => (
                    <>
                      <tr className={`debit-position`}>
                        {headers.map((e, index) => {
                          return e.renderPosition ? (
                            <td
                              colSpan={e.positionColSpan}
                              className={classNames(e.className)}
                              style={{
                                width: e.fixedWith,
                              }}
                            >
                              {e.renderPosition(pos)}
                            </td>
                          ) : null;
                        })}
                      </tr>
                      {openState.includes(pos._id) &&
                        pos.bookings
                          // .map((booking) =>
                          //   AccountingService.getCorrectedBooking(
                          //     pos.data.account,
                          //     booking
                          //   )
                          // )
                          .map((booking, i) => {
                            return (
                              <>
                                <tr
                                  className={`debit-booking ${
                                    i === pos.bookings.length - 1
                                      ? "last-booking"
                                      : ""
                                  }`}
                                >
                                  {headers.map((e, index) => {
                                    return (
                                      <td
                                        className={classNames(e.className)}
                                        style={{
                                          width: e.fixedWith,
                                        }}
                                      >
                                        {e.renderBooking(
                                          booking,
                                          pos,
                                          i,
                                          pos.bookings.length
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>

                                {/* {openState.includes(booking._id) && (
                                  <tr
                                    className={`debit-booking ${
                                      i === pos.bookings.length - 1
                                        ? "last-booking"
                                        : ""
                                    }`}
                                  >
                                    <td colSpan={headers.length}>
                                      <CBRentalAgreementDebitPositionInnerTable
                                        booking={booking}
                                        position={pos}
                                        allPositions={dataTransformed}
                                      />
                                    </td>
                                  </tr>
                                )} */}
                              </>
                            );
                          })}
                    </>
                  ))}
                </>
              )}
            </table>
          </div>
        );
      }}
    />
  );
};

export default CBRentalAgreementDebitPositions;
